
import { defineComponent } from 'vue';
import BaseCard from '@/components/base/BaseCard';
import SignUpForm from '@/components/forms/SignUpForm.vue';
import BaseAlertMessage from '@/components/base/BaseAlertMessage.vue';
import BaseInformation from '@/components/base/BaseInformation.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import useSignUp from '@/mixins/useSignUp';

const SignUpModal = defineComponent({
  components: {
    BaseCard,
    SignUpForm,
    BaseAlertMessage,
    BaseInformation,
    BaseButton,
  },

  ...useSignUp,
});

export default SignUpModal;
